<template>
    <div class="cooperation-page" v-if="page">
        <section class="main-section">
            <div class="container">
                <div class="row">
                    <div class="col">
                         <h1 v-if="page.title && page.title.rendered" class="section-title">
                            {{page.title.rendered}}
                        </h1>
                        <p v-if="pageFields && pageFields.sub_title" class="sub-title">
                          {{pageFields.sub_title}}
                        </p>
                        <p v-if="pageFields && pageFields.call_cation" class="color-text">
                          {{pageFields.call_cation}}
                        </p>
                    </div>
                    <div class="col">
                        <div class="counter-block" v-if="pageFields && pageFields.counters">
                            <SingleCounter
                              v-for="count in pageFields.counters"
                              :key="count"

                              :title="count.title"
                              :count="count.count"
                              :prefix="count.add_text"
                            />
                        </div>
                    </div>
                </div>
               
            </div>
        </section>

        <div v-html="page.content.rendered">

        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleCounter from '../global/SingleCounter.vue';
import { global } from '../../store/modules/global';

export default {
  components: {
    SingleCounter,
  },
    data() {
      return {
        page: null,
        pageId: null,
        pageFields: null,
        formData: {
            type: '',
            name: '',
            telNumber: '',
            userEmail: '',
            message: ''
        },
        showModal: false,
      };
  },
   computed: {
    ...mapGetters(["getPage"]),
  },
  mounted() {
    this.getPageContent()
  },
  watch: {
    showModal(newVal) {
      if(newVal) {
        
      setTimeout(() => {
          document.querySelector('.mainForm').addEventListener('submit', (e)=>{
            e.preventDefault()
            this.sendFormFeedBack()
          })
        }, 300);
      }
    }
  },
  updated() {
    this.eventModal()
  },
  beforeUnmount() {
    const scriptId = `js-script`;
    const scriptLink = document.getElementById(scriptId);

    if (scriptLink) {
      document.head.removeChild(scriptLink);
    }
  },
  methods: {
    ...mapActions(["sendForm"]),
    eventModal(){
      const items = document.querySelectorAll('.feedback-block')
      if(!items && !items.length) return

      items.forEach(item =>{
        const itemLink = item.querySelector('a[href="#feedback"]')
       
        
        itemLink.addEventListener('click', (e)=>{
          e.preventDefault()

          const titleBlock = item.querySelector('.elementor-heading-title');
          setTimeout(() => {
            document.querySelector('.mainForm #type').value = titleBlock.textContent
          }, 300);
          this.showModal = true
          //parentElement
          this.$swal({
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            willClose: () => {
              this.showModal = false; // Set status to false when closing the modal
            },
            customClass: 'customAlert',
            html: `<div class="form-wrap"><form class="mainForm" name="landingForm">
			    <input hidden="" id="shop_id">
			    <input hidden="" id="type" value="">
				<p>
					<input type="text" id="type-name" value="Пропозиція співпраці" disabled="">
				</p>
				<p><input type="text" id="name" placeholder="І'мя"></p>
				<p><input type="tel" id="tel-number" placeholder="Телефон"></p>
				<p><input type="email" id="user-email" placeholder="Електронна пошта"></p>
				<p><textarea type="text" id="message" rows="4" placeholder="Подробиці"></textarea></p>
				<!--p class="file-upload"> 
				    <label>
                      <input type="file" id="file" name="file">
                      <span class="filebtn">Вибрати файл</span>
                    </label>
                    <span id="filename" class="filename">
                 </p-->
				<p><input type="submit" value="Надіслати">
			</p></form></div>`
          });
        })
        
      })
    },
    getPageContent() {

          if(!this.getPage) {
            this.$router.push({name: '404'})
            return
          }

        this.page = this.getPage[0];
        this.pageId = this.getPage[0]?.id;
        this.pageFields = this.page.acf_fields

        this.loadJsFile('script')

    },

    loadJsFile(fileName) {
            if (!fileName || document.getElementById(fileName)) return;

            const domainElement = document.head && document.head.querySelector('[property="og:url"]');
            const domain = domainElement ? domainElement.content : "https://blyzenko.ua";

            const url = `${domain}/wp-content/themes/blyzenko-vue/${fileName}.js`;
            console.log(url)
            return new Promise((resolve, reject) => {
                const checkJQueryLoaded = setInterval(() => {
                    const jQueryScript = document.getElementById('jquery-core-js');
                    if (typeof jQuery !== "undefined" && jQueryScript) {
                        clearInterval(checkJQueryLoaded);

                        const link = document.createElement("script");
                        link.type = "text/javascript";
                        link.src = url;
                        link.id = `js-${fileName}`;
                        link.onload = () => resolve();
                        link.onerror = () => reject(new Error(`Failed to load js file: ${url}`));

                        // Insert the script after the jQuery script
                        jQueryScript.parentNode.insertBefore(link, jQueryScript.nextSibling);
                    }
                }, 100); // Check every 100ms

                // Optional timeout to reject the promise if jQuery never loads (e.g., after 10 seconds)
                setTimeout(() => {
                    clearInterval(checkJQueryLoaded);
                    reject(new Error('jQuery did not load within the expected time.'));
                }, 10000); // 10 seconds
            }).catch((error) => {
                const fileLink = document.getElementById(fileName);
                if (fileLink) {
                    document.head.removeChild(fileLink);
                }
            });
        },
        async sendFormFeedBack(){
          const formWrap = document.querySelector('.mainForm');

          this.formData.type = formWrap.querySelector('#type').value
          this.formData.name = formWrap.querySelector('#name').value
          this.formData.telNumber = formWrap.querySelector('#tel-number').value
          this.formData.userEmail = formWrap.querySelector('#user-email').value
          this.formData.message = formWrap.querySelector('#message').value

          await this.sendForm(this.formData)
        }
  },
}
</script>
<style lang="scss">

@import "../../assets/scss/cooperation-page.scss"; 

    .customAlert {
      padding: 0;
      background: transparent !important;

      .swal2-html-container {
        margin: 0;
        padding: 0;

        .form-wrap {
          max-width: 100%;
          margin-bottom: 0 !important;
        }
      }

      .swal2-close {
            background-color: transparent !important;
            border: none !important;
            border-radius: 0 !important;
            padding: 0 !important;
        &:focus {
          box-shadow: none;
        }
      }
    }
</style>

