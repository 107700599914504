import apiClient from '@/axios';
import axios from 'axios';

export const global = {
    state: () => ({
        nav: {
            main: null,
            footer: null
        },
        settings: [],
        form: null,
        fileId: null,
        fileError: null,
        formSendConfirm: null,
        formSendError: null,
        renderFormErrors: [],
        errors: []
    }),

    actions: {
        async fetchNavigation({ commit }:any, data:any) {
            try {
                const response = await apiClient.get(`/menu/${data.slug}`);

                commit('setNav', {nav: response, type: data.type })
                commit('setNavError', null)

            }  catch (error) {
                commit('setNavError', error)
            }        
              
          },
        async fetchGlobalSetting({ commit }:any, slug:string) {
            try {
                const response = await apiClient.get(`/theme-options`);

                commit('setGlobalSettings', response)
                commit('setNavError', null)

            }  catch (error) {
                commit('setNavError', error)
            }        
              
          },

        async fetchContactForm({ commit }:any, shortCode:any ){
            console.log(shortCode)
            if(!shortCode) return 

            try {
                const response = await apiClient.get(`/cf7-api`,{
                    params: {
                        shortcode: shortCode
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                commit('setContactForm', response)
                commit('setContactFormError', null)

            }  catch (error) {
                commit('setContactFormError', error)
            }
        },


        async sendForm( {commit}:any, formData:any ) {
            
            if(!formData) return 

            const domainElement = document.head && document.head.querySelector('[property="og:url"]');
            let domain = domainElement ? (domainElement as HTMLMetaElement).content : '';

            if(!domain) {
            domain = 'https://blyzenko.ua/'
            }

            const data = {
                '_wpcf7_unit_tag': 'wpcf7-f4630-o1',
                'shop-id': formData.shopId,
                'shop-address': formData.shopAddress,
                'type': formData.type,
                'user-name': formData.name,
                'tel-number': formData.telNumber,
                'user-email': formData.userEmail,
                'your-message': formData.message
            }

            await axios.post(domain+'/wp-json/contact-form-7/v1/contact-forms/4630/feedback', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': "application/json, */*;q=0.1"
                }
            })
            .then(response => {
                
                if (response.data.status === 'mail_sent') {
                    commit('setSendFormError', {result: response, status: true})
                } else {
                    commit('setSendFormError', {result: response, status: false})
                }
            }).catch(error => {
                console.error(error)
                commit('setSendFormError', error,)
            })
        },


        async sendContactFormForm( {commit}:any, formData:any ) {
            
            if(!formData) return 

            const domainElement = document.head && document.head.querySelector('[property="og:url"]');
            let domain = domainElement ? (domainElement as HTMLMetaElement).content : '';

            if(!domain) {
                domain = 'https://blyzenko.ua/'
            }

            commit('setSendFormError', null)

            await axios.post(domain+`/wp-json/contact-form-7/v1/contact-forms/${formData._wpcf7}/feedback`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': "application/json, */*;q=0.1"
                }
            })
            .then(response => {
                if (response.data.status === 'mail_sent') {
                    commit('setSendFormError', {result: response, status: true})
                } else {
                    commit('setSendFormError', {result: response, status: false})
                }
            }).catch(error => {
                console.error(error)
            })
        },

        async fetchUploadFile({ commit }: any, file: any) {
            if (!file) return;
        
            try {
                // Create a FormData object
                const formData = new FormData();
                formData.append('file', file);
        
                // Make the POST request with the FormData object
                const response = await apiClient.post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
        
                // Handle the response as needed
               // console.log('File uploaded successfully:', response.data);
                commit('setUploadFile', response.data)
            } catch (error:any) {
                commit('setUploadFileError', error.response ? error.response.data : error.message)
              //  console.error('Error uploading file:', error.response ? error.response.data : error.message);
            }
        },
    },
    mutations: {
        setNav(state:any, data:any) {

            if(!data) {
                state.nav[data.type] =  []
                return
            }

            state.nav[data.type] = data.nav.data
        },
        setGlobalSettings(state:any, settings:any) {
            if(!settings) {
                state.settings = []
                return
            }
            state.settings = settings.data
        },

        setContactForm(state:any, form:any) {
            if(!form) {
                state.form = []
                return
            }
            state.form = form.data
        },

         setContactFormError(state:any, error:any) {
            if(!error) {
                state.renderFormErrors = []
            }

            state.renderFormErrors = error.data
        }, 

        setSendFormError(state:any, error:any) {
            if(!error.result) {
                state.formSendError = null
                state.formSendConfirm = null
                return
            }

            if(error.status) {
                state.formSendConfirm = error.result.data
                state.formSendError = null
                return 
            }
            state.formSendConfirm = null
            state.formSendError = error.result.data
        },

        setUploadFile(state:any, data:any) {
            if(!data) {
                state.fileId = null
            }

            state.fileId = data.fileId

        },
        setUploadFileError(state:any, error:any) {
            if(!error) {
                state.fileError = null
            }

            state.fileError = error

        },

        setNavError(state:any, error:any) {
            if(!error) {
                state.errors = []
            }

            state.errors = error
        }
    },
    getters: {
        getNav(state:any) {
            return state.nav
        },
        getSettings(state:any) {
            return state.settings
        },
        getFormHtml(state:any) {
            return state.form
        },

        getSendFormConfirm(state:any) {
            return state.formSendConfirm
        },
        getSendFormError(state:any) {
            return state.formSendError
        },
        getFormFileId(state:any) {
            return state.fileId
        },
        getFormFileError(state:any) {
            return state.fileError
        }
    }
}
